@import 'base/app';

.not-found{
    padding-bottom: fluidFull(30,90);
    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    &__img {
        width: fluidFull(300, 1109);
        height: fluidFull(113.61587015329125, 420);
        object-fit: contain;
        user-select: none;
        pointer-events: none;
    }
    &__content {
        margin-top: 30px;
        @include _768 {
            margin-top: 20px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    &__description {
        margin-top: 10px;
        font-weight: 400;
        font-size: fluidFull(12,18);
        line-height: fluidFull(14,22);
        text-align: center;
        color: #1F2026;
    }
    &__btn {
        border: 1px solid $black;
        margin-top: 20px;
        @include _768 {
            margin-top: 15px;
        }
        &:hover {
            background: #fff;
            & span {
                color: $black;
            }
        }
    }
}